import "./Home.css";
// import * as links from './constants/links';
import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import {Col, Row, Container} from 'react-bootstrap';
import instagram from '../../assets/icons/instagram.svg';
import LogoGif from '../../assets/icons/logo.gif';
import EarthNight from '../../assets/images/earth-night.jpg';
import ProfilePhoto from '../../assets/images/profile_image.jpg';
import { Button } from "react-bootstrap";
import * as Constants from "../../Constants/Constants";
import PortfolioJob from "../../Components/PortfolioJob";
import mondayLogo from "../../assets/images/logos/monday.svg";
import lssgreen from "../../assets/images/logos/lssgreen.png";


class Home extends React.Component {
  render = () => {
    let ProfileButtons = 
    <div className="css-selector buttons-below-profile-photo" style={{zIndex:2, height:"max-content", display: "flex", flexDirection:"row"}}>
      <a href={Constants.LINKED_IN_PROFILE} target="_blank" rel="noreferrer" style={{flex:1}}><Button variant="outline-light" style={{width:"100%", height:"100%", borderRadius:"0"}}>LinkedIn</Button></a>
      <div style={{backgroundColor:"white", width:"10px"}} />
      <a className="contact-me-button tooltip-cls" href={"mailto:" + Constants.MY_EMAIL} target="_blank" rel="noreferrer" style={{flex:1}}><Button variant="outline-light" style={{width:"100%", height:"100%",  borderRadius:"0"}}>Contact Me<span className="contact-me-tooltip">{Constants.MY_EMAIL}</span></Button></a>
      <div style={{backgroundColor:"white", width:"10px"}} />
      <a href={Constants.LINK_TO_RESUME} target="_blank" rel="noreferrer" style={{flex:1}}><Button variant="outline-light" style={{width:"100%", height:"100%",  borderRadius:"0"}}>Resume</Button></a>
    </div>
    let certificates = 
      <div style={{height:"80px", width:"100%", display:"flex", flexDirection:"row", justifyContent:"center", background:"linear-gradient(to right, transparent, white, transparent)", padding:"3px"}}>
        <img src={mondayLogo} style={{height:"140%", width:"auto", objectFit:"scale-down", margin:"-10px 0 0 0", alignSelf:"flex-start", overflow: "hidden"}} />
        <img src={lssgreen} style={{height:"auto", width:"auto", objectFit:"contain"}} />
      </div>
  return (
    <div className="home-container" style={{display: "flex", flexDirection: "column"}}>
      <div style={{flex:1}}>
      <div className="desktop-container"  style={{position:"absolute", top:170, left:500, width:"560px", height:"560px", borderRadius:"280px", background:"radial-gradient(circle at 50%, transparent 70%, #990000 30%)"}} />
          <img src={EarthNight} style={{position:"absolute", width:"100%", minWidth:"100%", height:"500px", objectFit:"cover", backgroundColor:"white"}} alt="Loading..."/>
          <div className="home-img" style={{}} />

          <div className="desktop-container" style={{position:"absolute", top:150, left:-60, width:"560px", height:"560px", borderRadius:"280px", background:"radial-gradient(transparent 70%, white 20%)"}} />
          <div className="desktop-container" style={{position:"absolute", top:180, left:-30, width:"500px", height:"500px", borderRadius:"250px", background:"radial-gradient(white 20%, transparent 70%)"}} />
          <img className="desktop-container" src={ProfilePhoto} style={{position:"absolute", top:220, left:20, width:"400px", height:"400px", borderRadius:"200px"}} alt="profile"/>
          <div className="desktop-container" style={{background:"linear-gradient(to right, #990000, #FFCC00)", position:"absolute", top:490, left:280, width:"120px", height:"120px", borderRadius:"60px"}}/>
          <img className="desktop-container"  src={LogoGif} style={{position:"absolute", top:494, left:284, width:"112px", height:"112px", borderRadius:"56px"}} alt="profile"/>
         
          
            {ProfileButtons}
          

          <div className="desktop-container" style={{position:"absolute", top:490, left:782, width:"400px", height:"240px", background:"linear-gradient(to right, #990000, white)"}}/>
          <div className="desktop-container" style={{position:"absolute", top:478, left:780, width:"400px", height:"250px", backgroundColor:"white"}}/>
          <div className="desktop-container" style={{position:"absolute", top:470, left:498, width:"2px", height:"5px", backgroundColor:"#FFCC00"}}/>
          <div className="desktop-container" style={{zIndex:23, minWidth:"850px", position:"absolute", top:380, paddingLeft:"550px", paddingRight:"30px", width:"100%", height:"auto", display:"flex", flexDirection:"row"}}>
            <h1 className="home-title" style={{height:"max-content", fontSize:"70px", textTransform:"uppercase", textAlign:"start", position:"relative", display:"flex", flexDirection:"row"}}>
                <span className="hover-zoom">S</span>.&nbsp;
                <span className="hover-zoom">A</span>.&nbsp;
                <span className="hover-zoom">S</span>
                <span className="hover-zoom">u</span>
                <span className="hover-zoom">r</span>
                <span className="hover-zoom">d</span>
                <span className="hover-zoom">i</span>
                </h1>
            <div className="social-media" style={{flex:1, textAlign:"end", alignSelf:"center"}}>
                <a href={Constants.INSTAGRAM_PROFILE} target="_blank" rel="noreferrer">
                    <img src={instagram} className="insta-social" /> owlympic
                </a>
            </div>
          </div>
          <div className="desktop-container" style={{zIndex:22, position:"absolute", top:420, padding:"7% 0 0 580px", width:"100%", height:"auto", display:"flex", flexDirection:"row"}}>
              <div style={{textAlign:"end"}}>
                <h3>University of Southern California</h3>
                <h4><i>Los Angeles</i></h4>
                <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", width:"100%"}}>
                {"Astronautical".split('').map((key, i) => (
                    <span key={i} className="hover-zoom" style={{justifyContent:"flex-end", letterSpacing:"1px", fontStyle:"oblique", fontWeight:"500"}}>{key}</span>
                ))} &nbsp;
                {"Engineering".split('').map((key, i) => (
                    <span key={i} className="hover-zoom" style={{justifyContent:"flex-end", letterSpacing:"1px", fontStyle:"oblique", fontWeight:"400"}}>{key}</span>
                ))}
                </div>
              </div>
              <div style={{width:"4px", height:"auto", backgroundColor:"#FFCC00", marginLeft:"10px", marginRight:"10px"}} />
              <div style={{textAlign:"start"}}>
                <h3>Maharashtra Institute of Technology</h3>
                <h4><i>Pune</i></h4>
                <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", width:"100%"}}>
                {"Electronics".split('').map((key, i) => (
                    <span key={i} className="hover-zoom" style={{justifyContent:"flex-start", letterSpacing:"1px", fontStyle:"oblique", fontWeight:"500"}}>{key}</span>
                ))} &nbsp;&&nbsp; 
                {"Telecommunication".split('').map((key, i) => (
                    <span key={i} className="hover-zoom" style={{justifyContent:"flex-start", letterSpacing:"1px", fontStyle:"oblique", fontWeight:"500"}}>{key}</span>
                ))} &nbsp;
                {"Engineering".split('').map((key, i) => (
                    <span key={i} className="hover-zoom" style={{justifyContent:"flex-start", letterSpacing:"1px", fontStyle:"oblique", fontWeight:"400"}}>{key}</span>
                ))}
                </div>
              </div>
              
          </div>

          <div className="mobile-container" style={{flexDirection:"column", marginTop:"50px"}}>
          <img src={ProfilePhoto} style={{alignSelf:"center", zIndex:2, width:"50%", height:"auto", borderRadius:"50%"}} alt="profile"/>
          <div style={{zIndex:2, position:"absolute", left:"60%", top:"40vw"}}>
            <div style={{position:"absolute", background:"linear-gradient(to right, #990000, #FFCC00)", width:"11vw", height:"11vw", borderRadius:"50%"}}/>
            <img src={LogoGif} style={{position:"absolute", margin:"0.5vw", width:"10vw", height:"10vw", borderRadius:"50%"}} alt="profile"/>
          </div>
          </div>

          <div className="mobile-container">
            {ProfileButtons}
          </div>
      </div>
      <div className="portfolio-container">
      <h5 style={{marginTop:"10px", textAlign:"center", background:"linear-gradient(to right, transparent, white, transparent)"}}>Certificates</h5>
          {certificates}
          <br />
          <h1 style={{textAlign:"center"}}>Portfolio</h1>
        <Row className="show-grid" style={{margin:"20px", display:"flex", textAlign:"center",justifyContent:"center"}}>
          {Constants.PORTFOLIO_JOB.map((key, i) => (
            <Col md="5" style={{margin:"10px"}}><PortfolioJob key={i} card={key} /></Col>
          ))}
        </Row>
      </div>
    </div>
  );
  };
}

export default Home;
