import "./App.css";
import * as Constants from "./Constants/Constants";
import React from 'react';
// import ls from 'local-storage';
// import Script from 'react-load-script';

import "bootstrap/dist/css/bootstrap.min.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Home from "./pages/Home/Home";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { library } from "@fortawesome/fontawesome-svg-core";
// import {
//   faSearch, faLocationArrow
// } from "@fortawesome/free-solid-svg-icons";

// library.add(faSearch, faLocationArrow);


const NoMatchFound = () => {
  return (
    <h3
      className="center"
      style={{
        marginTop: "100px",
        display: "block",
        width: "100%",
        textAlign: "center",
      }}
    >
      404 - Not found
    </h3>
  );
};

const UnderConstruction = () => {
  return (
    <div>
    <h3
      className="center"
      style={{
        marginTop: "100px",
        marginBottom: "100px",
        display: "block",
        width: "100%",
        textAlign: "center",
      }}
    >
      Under Construction
    </h3>
    </div>
  );
};

class App extends React.Component {
  render = () => {
  return (
    <div className="page-container">
      <div className="content-wrap">
        <Router>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            {/* <Route exact path={Constants.LINK_TO_RESUME}>
              <div>
                This is my resume
              </div>
            </Route> */}
            <Route component={NoMatchFound} />
          </Switch>
        </Router>
      </div>
      
    </div>
  );
  };
}

export default App;
