import './PortfolioJob.css';
import { Row, Col, Card } from 'react-bootstrap';
import * as Constants from '../Constants/Constants';

const Box = (props) => { 
    return (
    <Card className="portfolio-card">
        <img src={props.card.img} style={{width:"100%", height:"500px", objectFit:"contain", backgroundColor: "white"}} />
        <div style={{textAlign: "start", margin:"10px"}}>
        <h4>{props.card.title}</h4>
        <hr />
        <p>{props.card.description}</p>
        </div>
        <br />
        <div style={{textAlign:"center"}}>
        {props.card.technologies.map((key, i) => (
            Constants.TECHNOLOGY_LOGOS[key] ? (
                <div className="tooltip-cls tech-img-portfolio">
                <img className="hover-zoom-p" style={{height:"auto", width:"80px", objectFit:"contain", padding:"5px", margin:"0 8px"}} key={i} src={Constants.TECHNOLOGY_LOGOS[key].logo}/>
                <span className="tech-img-portfolio-tooltip">{Constants.TECHNOLOGY_LOGOS[key].text}</span>
                </div>
            ) : ""
        ))}
        </div>
    </Card>
    )
}

export default Box;